<template>
  <div class="mx-0 lg:bg-gray-500 lg:w-full"
       data-nosnippet
  >
    <Container class="flex flex-col flex-wrap justify-between w-full pb-8 text-xs text-gray-200 lg:flex-row lg:flex-nowrap lg:py-5">
      <!-- Legal links -->
      <nav class="flex flex-col flex-wrap p-4 space-y-2 bg-gray-500 lg:flex-shrink lg:flex-row lg:space-y-0 lg:p-0">
        <span v-for="(item, i) in navigation"
              :key="item.id"
              class="text-center lg:w-auto lg:text-left"
              :class="[{ 'lg:ml-2': i !== 0 }]"
        >
          <span v-if="i !== 0"
                class="hidden text-gray-300 lg:mr-2 lg:inline"
          >|</span>
          <FooterLink :to="item.url"
                      class="first:font-bold"
          >
            {{ item.title }}
          </FooterLink>
        </span>
      </nav>

      <div class="pt-10 text-center lg:pt-0 lg:text-right">
        © 2010 - {{ currentYear() }} Outfit7 Limited and related entities. All rights reserved.
      </div>
    </Container>
  </div>
</template>

<script setup lang="ts">
import navigation from '~/content/navigation/footer_legal.json'

function currentYear() {
  return new Date().getFullYear()
}
</script>
